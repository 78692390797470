<template>
  <video-player
    style="width: 100%; height: 100%"
    ref="camOnlinePlayer"
    :options="playerOptions"
  />
</template>
<script>
import videojs7 from "vue-videojs7";
import videojs from "video.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CamOnlineModule",
  components: {
    VideoPlayer: videojs7.videoPlayer,
  },
  props: {
    module: { default: () => {}, required: true },
  },

  data() {
    return {
      playerOptions: {
        autoplay: true,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
      },
    };
  },

  methods: {
    ...mapActions("camOnline", ["getServers"]),

    play() {
      if (this.url == null) {
        console.error("Could not compile url for stream");

        return;
      }

      const video = {
        withCredentials: false,
        type: "application/x-mpegurl",
        src: this.url,
        Authorization: `Bearer ${this.server.accessToken}`,
      };

      videojs.Vhs.xhr.beforeRequest = (option) => {
        option.headers = {
          Authorization: `Bearer ${this.server.accessToken}`,
        };
      };

      this.player.reset();
      this.player.src(video);
      this.player.play();
    },
  },

  computed: {
    ...mapGetters("camOnline", ["getServer"]),

    player() {
      return this.$refs.camOnlinePlayer.player;
    },

    serverId() {
      var id = this.module.meta.find((e) => e.key === "camonline.server");
      return id.value ?? "";
    },

    server() {
      var srv = this.getServer(this.serverId);
      return srv;
    },

    url() {
      var stream = this.module.meta.find((e) => e.key === "camonline.stream");
      var accessToken = this.server.accessToken;

      if (accessToken == undefined || stream == undefined) return null;

      return `${this.server.url}/api/hls/${stream.value}/index.m3u8?access_token=${accessToken}`;
    },
  },

  async mounted() {
    await this.getServers();
    setTimeout(() => this.play(), Math.floor(Math.random() * 2500) + 500);
  },
};
</script>

<style>
.video-js {
  width: 100%;
  height: 100%;
}
</style>
